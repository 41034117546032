import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/Seo'
import {
  Flex,
  Box,
  Heading
} from '@chakra-ui/react'
import { PrismicRichText } from '@prismicio/react'
import { CustomLink } from '../utils/customLink'
import PageHero from '../components/PageHero/PageHero'
import MobilePageHero from '../components/PageHero/MobilePageHero'
import SliceZone from '../components/SliceZone/SliceZone'
import { getImage } from 'gatsby-plugin-image'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import Sidebar from '../components/Sidebar/Sidebar'
import MooseLottie from '../components/Footer/Moose/MooseLottie'


const BidsRfpProjectPageTemplate = (props) => {
      // Explicitly delcare props and destructure the other properties:
      const { location, data, ...rest } = props;
      const pageLocation = location.pathname

  if (!data) return null; 

  const document = data.prismicBidRfpProjectPage.data
  const backgroundImage = getImage(document.hero_image.localFile)
  const image = getImage(backgroundImage);

  const menuItems = null || document.sidebar_menu.document?.data.body

  return (
    <Layout>
      <Seo title={document.page_title.text}  />

<PageHero 
  heroImage={image}
  pageTitle={document.page_headline?.text}
/>
      <Box position="relative" zIndex="1">
      <Flex w="100%" h="100%" flexWrap="wrap" flexDirection={{base: 'column-reverse', lg: 'row'}}>
        <Box w={{base: '100%', lg: '25%'}}>
              <Sidebar
                menuHeading={menuItems && document.sidebar_menu.document.data.menu_title.text}  
                menuItems={menuItems}
                pageLocation={pageLocation}
              />
           </Box>
           <Box w={{base: '100%', lg: '75%'}} bg="white" p="8" mb={{base: '4rem', md: '36rem'}}>
            <Heading as="h2" variant="page-title">
              {document.page_title.text}
            </Heading>
            <Heading as="h3" fontSize="2rem" variant="page-subtitle">
              {document.subtitle.text}
            </Heading>

            <PrismicRichText 
    field={document.page_content.richText} components={{
      hyperlink: ({children, node}) => (
        <CustomLink node={node}>{children}</CustomLink>
      ),
    }}/>
            
            <SliceZone sliceZone={document.body}/>

      </Box>
      <MooseLottie />
        </Flex>
      </Box>
      
    </Layout>
  )
}



    export const query = graphql`
    query BidsPageQuery($uid: String!) {
      prismicBidRfpProjectPage(uid: {eq: $uid}) {
        data {
          page_headline {
            text
          }
          subtitle {
            text
          }
          sidebar_menu {
            document {
              ... on PrismicSidebarNavigation {
                id
                data {
                  menu_title {
                    text
                  }
                  body {
                    ... on PrismicSidebarNavigationDataBodySidebarNavAccordionItem {
                      id
                      slice_type
                      primary {
                        title_link {
                          url
                        }
                        navigation_group_title {
                          text
                        }
                      }
                      items {
                        link {
                          url
                        }
                        item_title {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          page_title {
            text
          }
          page_content {
            richText
          }
          hero_image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
          body {
            ... on PrismicBidRfpProjectPageDataBodyBidRfpProjectAccordion {
              id
              slice_type
              primary {
                accordion_title {
                  text
                }
                accordion_subtitle {
                  text
                }
              }
              items {
                accordion_item_id {
                  text
                }
                item_title {
                  text
                }
                item_description {
                  richText
                }
              }
            }
            ... on PrismicBidRfpProjectPageDataBodyContactCard {
              id
              slice_type
              primary {
                department_contact_information {
                  text
                }
              }
              items {
                contact_title {
                  text
                }
                contact_phone {
                  text
                }
                contact_name {
                  text
                }
                contact_extension
                contact_email {
                  text
                }
              }
            }
            ... on PrismicBidRfpProjectPageDataBodyIframeSlice {
              id
              slice_type
              primary {
                iframe_source {
                  text
                  richText
                  html
                }
              }
            }
            ... on PrismicBidRfpProjectPageDataBodyFormLinkModule {
              id
              slice_type
              primary {
                form_links_title {
                  text
                }
              }
              items {
                form_links {
                  url
                }
              }
            }
            ... on PrismicBidRfpProjectPageDataBodyRichText {
              id
              slice_type
              primary {
                content {
                  richText
                }
              }
            }
            ... on PrismicBidRfpProjectPageDataBodyRawHtmlSlice {
              id
              slice_type
              primary {
                html_content {
                  html
                }
              }
            }
          }
        }
        _previewable
      }
    }
  `

export default withPrismicPreview(BidsRfpProjectPageTemplate)
